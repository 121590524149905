import React, { useState, useEffect } from "react";

class TIMER_STATES {
  static STOPPED = "Stopped";
  static RUNNING = "Running";
  static FINISHED = "Finished";
}

const duration = 180;

export function Timer(props) {
  const [timer_state, set_timer_state] = useState(TIMER_STATES.STOPPED);
  const [clock, set_clock] = useState(props.duration);

  useEffect(() => {
    if (timer_state === TIMER_STATES.RUNNING) {
      const timer = setInterval(() => {
        if (clock > 0) {
          set_clock(clock - 1);
        } else {
          //Timer finished
          props.on_finished();
          clearInterval(timer);
          set_timer_state(TIMER_STATES.FINISHED);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timer_state, clock]);

  function timer_button_clicked(e) {
    if (timer_state === TIMER_STATES.STOPPED) {
      props.on_started();
      set_timer_state(TIMER_STATES.RUNNING);
    } else if (timer_state === TIMER_STATES.FINISHED) {
      props.on_reset();
      set_clock(props.duration);
      set_timer_state(TIMER_STATES.STOPPED);
    }
  }

  function get_time_label() {
    if (timer_state === TIMER_STATES.STOPPED) return `${clock}s`;
    else if (timer_state === TIMER_STATES.RUNNING) return `${clock}s remaining`;
    else if (timer_state === TIMER_STATES.FINISHED) return `Finished`;
  }

  return (
    <div className="Timer">
      <button
        disabled={timer_state === TIMER_STATES.RUNNING}
        className="timer_button"
        onClick={timer_button_clicked}
      >
        {timer_state === TIMER_STATES.STOPPED ? "Start" : "Reset"}
      </button>
      <label className="clock_label">{get_time_label()}</label>
    </div>
  );
}
