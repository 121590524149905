import "./App.css";
import React, { useState, useEffect } from "react";
import { gen_problem } from "./problem_generator";
import { Settings } from "./Settings";
import { Timer } from "./Timer";

function App() {
  const [current_problem, set_current_problem] = useState(
    gen_problem(1, 1, "*", 100, 0, 0)
  );
  const [user_input, set_user_input] = useState("");

  const [is_counting, set_is_counting] = useState(false);
  const [count, set_count] = useState(0);

  // Settings state
  const [term1_digits, set_term1_digits] = useState(2);
  const [term2_digits, set_term2_digits] = useState(1);
  const [term1_zeros, set_term1_zeros] = useState(0);
  const [term2_zeros, set_term2_zeros] = useState(0);
  const [operator, set_operator] = useState("*");

  // Test time duration
  const duration = 180;

  function gen_new_problem() {
    set_current_problem(gen_problem(term1_digits, term2_digits, operator, 100, term1_zeros, term2_zeros));
  }

  /** Check for updated settings */
  useEffect(() => {
    gen_new_problem();
  }, [term1_digits, term2_digits, operator, term1_zeros, term2_zeros]);

  /** Check if user has answered correctly */
  useEffect(() => {
    // If correct value, then give a new exercise and clear input
    if (current_problem.equals(user_input)) {
      if (is_counting) {
        set_count(count + 1);
      }
      set_user_input("");
      set_current_problem(
        gen_problem(term1_digits, term2_digits, operator, 100, term1_zeros, term2_zeros)
      );
    }
  }, [user_input]);

  function handle_user_input(e) {
    set_user_input(e.target.value);
  }

  function on_timer_started() {
    gen_new_problem();
    set_is_counting(true);
  }

  function on_timer_finished() {
    set_is_counting(false);
  }

  function on_timer_reset() {
    set_count(0);
  }

  return (
    <div className="App">
      <Timer
        duration={duration}
        on_started={on_timer_started}
        on_finished={on_timer_finished}
        on_reset={on_timer_reset}
      />
      <div className="mm_container">
        <div className="mm_problem">
          {current_problem
            ? current_problem.as_str()
            : "Waiting for problem..."}
        </div>
        <input
          type="number"
          className="user_answer"
          value={user_input}
          onChange={handle_user_input}
        />
      </div>
      <div className="small_seperator" />
      <label>Count: {count}</label>
      <div className="big_seperator" />
      <Settings
        term1_digits={term1_digits}
        set_term1_digits={set_term1_digits}
        term2_digits={term2_digits}
        set_term2_digits={set_term2_digits}
        operator={operator}
        set_operator={set_operator}
        term1_zeros={term1_zeros}
        set_term1_zeros={set_term1_zeros}
        term2_zeros={term2_zeros}
        set_term2_zeros={set_term2_zeros}
      />
      <div className="vert_spacing" />

      <label className="info_label">Calculations round down.</label>
    </div>
  );
}

export default App;
