const legal_operators = "*/+-";

/**@TODO Implement */
let significant_figures = 3;

/**@TODO implement specification of problem-difficulty (those with carry and with more mental addition having to be done) */
export function gen_problem(t1_digits, t2_digits, operator, difficulty, t1_zeros, t2_zeros) {
  let term1 = Math.floor(Math.random() * 10 ** t1_digits);
  let term2 = Math.floor(Math.random() * 10 ** t2_digits);

  // For 1-digit numbers only use range [2, 7] to avoid trivial problems
  if (t1_digits === 1) term1 = Math.floor(Math.random() * 7 + 2);
  if (t2_digits === 1) term2 = Math.floor(Math.random() * 7 + 2);

  // Add trailing zeros
  term1 *= 10**t1_zeros
  term2 *= 10**t2_zeros

  return new Problem(term1, term2, operator);
}

class Problem {
  constructor(term1, term2, operator) {
    this.term1 = term1;
    this.term2 = term2;

    if (legal_operators.includes(operator)) {
      this.operator = operator;
    } else {
      throw "IllegalArgumentException - Operator must be *, /, +, or -";
    }

    this.result = this.calc_result();
  }

  calc_result() {
    if (this.operator === "*") {
      return this.term1 * this.term2;
    } else if (this.operator === "/") {
      return this.term1 / this.term2;
    } else if (this.operator === "+") {
      return this.term1 + this.term2;
    } else if (this.operator === "-") {
      return this.term1 - this.term2;
    }
  }

  equals(input) {
    return Math.round(input) === Math.floor(this.result);
  }

  as_str() {
    if (this.operator === "*") {
      return `${this.term1} × ${this.term2}`;
    }
    return `${this.term1} ${this.operator} ${this.term2}`;
  }
}
