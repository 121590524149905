import React, { useState, useEffect } from "react";

export function Settings(props) {
  return (
    <div className="Settings">
      <label>Settings</label>
      <div className="left_align">
        <label className="small_text settings_label">
          Term 1 digits
          <input
            name="Term 1 Digits"
            value={props.term1_digits}
            onChange={(e) => props.set_term1_digits(e.target.value)}
          />
        </label>
        <label className="small_text settings_label">
          Term 2 digits
          <input
            name="Term 2 Digits"
            value={props.term2_digits}
            onChange={(e) => props.set_term2_digits(e.target.value)}
          />
        </label>

        <label className="small_text settings_label">
          Term 1 Trailing Zeros
          <input
            name="Term 1 Trailing Zeros"
            value={props.term1_zeros}
            onChange={(e) => props.set_term1_zeros(e.target.value)}
          />
        </label>

        <label className="small_text settings_label">
          Term 2 Trailing Zeros
          <input
            name="Term 2 Trailing Zeros"
            value={props.term2_zeros}
            onChange={(e) => props.set_term2_zeros(e.target.value)}
          />
        </label>

        <label className="small_text settings_label">
          {"Operator "}
          <select
            value={props.operator}
            onChange={(e) => props.set_operator(e.target.value)}
            id="dropdown"
          >
            <option value="*">×</option>
            <option value="/">/</option>
            <option value="+">+</option>
            <option value="-">-</option>
          </select>
        </label>
      </div>
    </div>
  );
}
